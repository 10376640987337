<template>
  <div>
    <!-- loading animation -->
    <template v-if="!loaded">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- data not found -->
    <template v-else-if="hasNoResources">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-alert dense type="info">
              <v-row align="center">
                <v-col class="grow"
                  >Causal graph is under calculation. Please wait for a
                  while.</v-col
                >
                <v-col class="shrink">
                  <v-btn text @click="onTrainCausalGraph">Generate</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container style="height: 100%">
        <v-row>
          <v-col>
            <div>Training method</div>
            <form class="mt-5">
              <v-text-field
                :value="dataset.name"
                label="Dataset"
                required
                @change="onChangeDatasetGroup"
                @blur="$v.dataset.$touch()"
                dense
                readonly
              ></v-text-field>
              <v-text-field
                :value="trainParam.model_type"
                label="Objective"
                required
                @change="$v.trainParam.model_type.$touch()"
                @blur="$v.trainParam.model_type.$touch()"
                dense
                readonly
              ></v-text-field>
            </form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>Model details</div>
            <form class="mt-5">
              <v-text-field
                v-model="trainParam.model_name"
                label="Model name"
                :error-messages="nameErrors"
                required
                @change="$v.trainParam.model_name.$touch()"
                @blur="$v.trainParam.model_name.$touch()"
                dense
              ></v-text-field>
              <v-select
                v-model="trainParam.y_var"
                :items="targetColumnItems"
                label="Target column"
                :error-messages="targetTraitErrors"
                required
                @change="$v.trainParam.y_var.$touch()"
                @blur="$v.trainParam.y_var.$touch()"
                dense
              ></v-select>
              <v-select
                v-model="trainParam.time_stamp"
                :items="colnameForTimeStamp"
                label="Time stamp"
                :error-messages="timeStampErrors"
                @input="$v.trainParam.time_stamp.$touch()"
                @blur="$v.trainParam.time_stamp.$touch()"
                dense
              ></v-select>
              <v-select
                v-model="trainParam.segment_value"
                :items="colnameForSegmentValue"
                label="Segment value (Optional)"
                :error-messages="segmentValueErrors"
                @input="$v.trainParam.segment_value.$touch()"
                @blur="$v.trainParam.segment_value.$touch()"
                dense
              ></v-select>
            </form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>Training options</div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="colnameForTrainingTraits"
              item-key="name"
              show-select
              class="elevation-1 mb-2"
              mobile-breakpoint="0"
              dense
            >
              <template v-slot:top>
                <v-text-field
                  class="pl-4"
                  :value="`${$t('selectedTraits')}: ${selected.length}`"
                  readonly
                  :error-messages="trainingTraitsErrors"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>Training Confounder</div>
            <v-data-table
              v-model="selectedConfounders"
              :headers="headers"
              :items="confounderItems"
              item-key="name"
              show-select
              class="elevation-1 mb-2"
              mobile-breakpoint="0"
              dense
            >
              <!-- <template v-slot:top>
                <v-text-field
                  class="pl-4"
                  :value="`${$t('selectedConfounders')}: ${
                    selectedConfounders.length
                  }`"
                  readonly
                  :error-messages="selectedConfoundersErrors"
                ></v-text-field>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex pt-0">
            <v-btn
              color="primary"
              class="me-3"
              @click.prevent="onTrain"
              :disabled="onTrainBtnLocked"
            >
              START TRAINING
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import data from "@/models/data";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  name: "DatasetGroupsModelCreate",
  data() {
    return {
      onTrainBtnLocked: false,
      loaded: false,
      hasNoResources: null,
      datasetGroups: null,
      dataset: null,
      datasetItems: [],
      objectiveItems: [],
      targetColumnItems: [],
      trainParam: {
        id: null,
        time_stamp: null,
        segment_value: null,
        y_var: null,
        x_var: null,
        model_name: null,
        model_type: null,
      },
      selected: [],
      selectedConfounders: [],
    };
  },
  validations: {
    dataset: {
      required,
    },
    trainParam: {
      time_stamp: {
        required,
      },
      segment_value: {
        // required,
      },
      y_var: {
        required,
      },
      model_name: {
        required,
      },
      model_type: {
        required,
      },
    },
    selected: {
      required,
    },
    selectedConfounders: {
      // required,
    },
  },
  watch: {
    colnameForTimeStamp() {
      this.selected = [];
      this.selectedConfounders = [];
    },
    colnameForSegmentValue() {
      this.selected = [];
      this.selectedConfounders = [];
    },
    colnameForTrainingTraits() {
      this.selected = [];
      this.selectedConfounders = [];
    },
    selected() {
      this.selectedConfounders = [];
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("traitName"),
          value: "name",
        },
      ];
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.trainParam.model_name.$dirty) return errors;
      !this.$v.trainParam.model_name.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    targetTraitErrors() {
      const errors = [];
      if (!this.$v.trainParam.y_var.$dirty) return errors;
      !this.$v.trainParam.y_var.required && errors.push(this.$t("required"));
      return errors;
    },
    timeStampErrors() {
      const errors = [];
      if (!this.$v.trainParam.time_stamp.$dirty) return errors;
      !this.$v.trainParam.time_stamp.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    segmentValueErrors() {
      const errors = [];
      if (!this.$v.trainParam.segment_value.$dirty) return errors;
      // !this.$v.trainParam.segment_value.required &&
      //   errors.push(this.$t("required"));
      return errors;
    },
    trainingTraitsErrors() {
      const errors = [];
      if (!this.$v.selected.$dirty) return errors;
      !this.$v.selected.required && errors.push(this.$t("required"));
      return errors;
    },
    // selectedConfoundersErrors() {
    //   const errors = [];
    //   if (!this.$v.selectedConfounders.$dirty) return errors;
    //   !this.$v.selectedConfounders.required && errors.push(this.$t("required"));
    //   return errors;
    // },
    colnameForTimeStamp() {
      return _.filter(this.targetColumnItems, (value) => {
        return value !== this.trainParam.y_var;
      });
    },
    colnameForSegmentValue() {
      return _.filter(this.colnameForTimeStamp, (value) => {
        return value !== this.trainParam.time_stamp;
      });
    },
    colnameForTrainingTraits() {
      const xTactics = _.filter(this.colnameForSegmentValue, (value) => {
        return value !== this.trainParam.segment_value;
      });
      return xTactics.map((xTactic) => {
        return { name: xTactic };
      });
    },
    confounderItems() {
      const toRemove = this.selected.map((x) => x.name);
      const xTactics = _.filter(this.colnameForSegmentValue, (value) => {
        return value !== this.trainParam.segment_value;
      });
      const items = _.filter(xTactics, (value) => {
        return !toRemove.includes(value);
      });
      return items.map((item) => {
        return { name: item };
      });
    },
    loading() {
      return this.$store.state.loader.loading;
    },
  },
  methods: {
    async getDatasetGroups() {
      let res = await data.getDatasetGroups();
      this.datasetGroups = res.data.DatasetGroup;
      const id = this.$route.params.id;
      if (id) {
        // model create from the datasetGroups page
        // get specific datasetGroup by id
        const datasetGroup = this.datasetGroups.find((datasetgroup) => {
          return datasetgroup.id === parseInt(id);
        });
        // set dataset options
        this.datasetItems = [
          {
            name: datasetGroup.name,
            id: datasetGroup.id,
          },
        ];

        // set default option
        this.dataset = this.datasetItems[0];

        // click default option
        this.onChangeDatasetGroup(id);
      } else {
        // model create from other pages
        // set dataset options
        this.datasetItems = this.datasetGroups.map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        });
      }
      this.loaded = true;
    },
    async onChangeDatasetGroup(id) {
      // get objective options
      const datasetGroup = this.datasetGroups.find((datasetGroup) => {
        return datasetGroup.id === parseInt(id);
      });
      const datasetGroupDomains = await data.getDatasetGroupDomains();

      const datasetGroupDomain = datasetGroupDomains.find(
        (datasetGroupDomain) => {
          return datasetGroupDomain.id === datasetGroup.domain.id;
        }
      );
      this.objectiveItems = [datasetGroupDomain.display_name];

      // set default option
      this.trainParam.model_type = this.objectiveItems[0];

      // get data dataset
      const datasets = await data.getDatasets(datasetGroup.id);
      const dataDataset = datasets.find((dataset) => {
        return dataset.name === "data";
      });

      // get column name of selected dataset
      const response = await data.getDatasetHeaders({
        datasetGroupId: datasetGroup.id,
        datasetId: dataDataset.id,
      });

      this.targetColumnItems = response;
    },
    async onTrain() {
      this.onTrainBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        const x_var = _.map(this.selected, "name");
        const x_confounder = _.map(this.selectedConfounders, "name");
        this.trainParam.x_var = x_var;
        const payload = {
          arguments: {
            segment_value: this.trainParam.segment_value, // "state"
            time_stamp: this.trainParam.time_stamp, // "time_stamp"
            x_confounder: x_confounder, // ["trend"]
            x_tactic: this.trainParam.x_var, // ["consumer_crm", "consumer_website"]
            y_var: this.trainParam.y_var, // "total_rx"
          },
          dataset_group: this.$route.params.id, //  1
          name: this.trainParam.model_name,
        };

        try {
          const response = await data.createModel(payload);
          if (response.status === 202) {
            // this.$store.dispatch("fetchModel");
            this.$dialog.message.success(this.$t("modelCreated"), {
              position: "top",
            });
            const id = this.$route.params.id;
            this.$router.push(`/datasetGroups/${id}/overview`);
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.onTrainBtnLocked = false;
    },
  },
  created() {
    this.getDatasetGroups();
  },
};
</script>

<style scope></style>
